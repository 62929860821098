<script setup lang="ts">
const nuxt = useNuxtApp();
const { __ } = nuxt.vueApp.config.globalProperties;

useHead({ title: __('Overview') });
</script>

<template>
  <div class="mb-4 font-roboto text-3.5xl font-light">
    {{ __('Overview') }}
  </div>
</template>
